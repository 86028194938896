<template lang="pug">
  .pi-video-external(:class="mainClass")
    div(:style="style")
      iframe(
        v-if="youtubeID"
        :src="embedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        @touchstart="togglePlay"
        ref="videoFrame"
      )
  </template>

<script>
import { debounce, isString } from 'lodash'

export default {
  name: 'pi-video-external',
  props: [
    'row',
    'nbSection',
    'rowIndex',
    'reponse',
    'conf',
    'wordPicked',
    'courseCategoryId',
    'course',
    'dataset',
    'datasetsAlreadyComputed',
    'rowCount',
  ],
  data() {
    return {
      resize: () => {},
      width: 'inherit',
      isInResizableWidget: false,
      isPlaying: false,
    }
  },
  computed: {
    mainClass() {
      return {
        'max-visible-coverage': !this.isInResizableWidget,
      }
    },
    style() {
      return {
        width: this.width,
        margin: 'auto',
      }
    },
    youtubeID() {
      const regexExpression = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?‌=]*)?/
      if (regexExpression.test(this.row.data.url) && isString(this.row.data.url.match(regexExpression)[1])) {
        return this.row.data.url.match(regexExpression)[1]
      }
      return null
    },
    embedUrl() {
      if (!this.youtubeID) return ''
      return `https://www.youtube.com/embed/${this.youtubeID}?autoplay=1&rel=0&modestbranding=1&cc_load_policy=1&enablejsapi=1`
    },
  },
  created() {
    window._vm_video_external = this
  },
  mounted() {
    this.resize = debounce(this._resizeHandler, 200)
    this.setWidth()
    window.setTimeout(() => this.setWidth(), 1000)
    const resizableParent = this.$el.closest('.resizable') || window
    resizableParent.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    const resizableParent = this.$el.closest('.resizable') || window
    resizableParent.removeEventListener('resize', this.resize)
  },
  methods: {
    _resizeHandler() {
      this.setWidth()
    },
    setWidth() {
      let width = '100%'
      this.isInResizableWidget = Boolean(this.$el?.closest('.resizable'))
      const coverage = this.$el.closest('.resizable') || this.$el.closest('.brick-part-wrapper') || this.$el
      const isInBrickPage = !this.$el.closest('.resizable') && Boolean(this.$el.closest('.brick-part-wrapper'))

      if (coverage) {
        let { height } = coverage.getBoundingClientRect()
        if (isInBrickPage) {
          height -= coverage.querySelector('.brick-title').getBoundingClientRect().height
        }
        const maxwidth = Math.floor((height * 100) / 56.5)

        if (coverage.getBoundingClientRect().width > maxwidth) {
          width = `${maxwidth}px`
        } else if (!isInBrickPage) {
          width = `${coverage.getBoundingClientRect().width - 10}px`
        }
      }
      this.width = width
    },
    togglePlay() {
      if (this.$refs.videoFrame) {
        this.isPlaying = !this.isPlaying
        this.$refs.videoFrame.contentWindow.postMessage(
          JSON.stringify({
            event: 'command',
            func: this.isPlaying ? 'playVideo' : 'pauseVideo',
          }),
          '*',
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pi-video-external {
  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
}
</style>
